import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <section className="w-full h-screen grid" style={{ maxHeight: 900 }}>
        <StaticImage
          className="object-cover w-full h-full"
          src="../images/rensner-profil.png"
          alt="Dateilaufnahme eines Ölgemäldes"
          style={{ gridArea: "1/1" }}
          placeholder="blurred"
          objectPosition="center bottom"
        />
        <div
          className="object-cover w-full h-full  relative top-0"
          style={{ gridArea: "1/1", paddingTop: 0 }}
        >
          <div className="container mx-auto flex flex-row justify-end">
            <h1 className="hidden">rensner</h1>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About
